import React from "react"
import { createRoot } from "react-dom/client"
import { Provider } from "react-redux"
import { store } from "./store"

import Router from "./app/router"
import "./styles/tailwind.css"
// import ErrorPage from "./layouts/ErrorPage"
// import reportWebVitals from "./reportWebVitals"

// const MODE = import.meta.env.VITE_APP_MODE || "production"

const root = createRoot(document.getElementById("root") as HTMLElement)

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <Router />
    </Provider>
  </React.StrictMode>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals()
