import { configureStore } from "@reduxjs/toolkit"
import { setupListeners } from "@reduxjs/toolkit/query"

import { api } from "@/api/api"

export interface AppState {
  [api.reducerPath]: ReturnType<typeof api.reducer>
}

// const logger = (_ : any) => (next: any) => (action: any) => {
//   console.log('dispatching', action)
//   // let result = next(action)
//   // console.log('next state', store.getState())
//   return next(action)
// }

export const store = configureStore({
  reducer: {
    // Add the generated reducer as a specific top-level slice
    [api.reducerPath]: api.reducer,
  },
  // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(api.middleware),
  // .concat(logger),
})

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch)
